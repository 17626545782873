@import "../scss/variables";

.edit-container {
  @media #{$md} {
    width: 728px;
    margin: 0 auto;
  }
  @media #{$xl} {
    width: 1092px;
  }
  .control-bar {
    .summary {
      margin-bottom: 1em;
    }
    button {
      width: 300px;
      margin: 0 0 30px;
    }

    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 5px;
    div:nth-child(2) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    p {
      margin-bottom: 2px;
      letter-spacing: 1px;
    }
    span {
      font-weight: bolder;
    }



    @media #{$md} {
      flex-direction: row;
      margin: 16px;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}

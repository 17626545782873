@import "../scss/variables";

.dropdown {
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.842);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: $primary;
  color: white;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 12px;
  }

  li:hover {
    background-color: rgba(0, 0, 0, 0.89);
    cursor: pointer;
  }
}

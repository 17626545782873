@import "../scss/variables";

.slideshow {
  overflow: hidden;
  background: $primary;
  height: 100vh;
  .carousel {
    height: 100%;
    .image {
      height: 100%;
    }
    color: white;
    .slide-with-content {
      display: flex;
      width: 100%;

      .image {
        width: 65%;
      }
    }
    .content {
      flex: 1;
      align-self: center;
      padding: 3em;
    }

    .bookingsWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;
      img {
        width: 60%;
        height: 60%;
        margin-top: -2%;
      }
      h1 {
         height: 20vh;
        font-size: 8vh;
      }
      h4 {
         height: 15vh;
        font-size: 5vh;
      }
      ul {
        //height: 60vh;
        
        list-style-type: none;
        li {
          font-size: $body;
          margin-bottom: 0.2em;
          font-size: 3vh;
        }
      }
    }
    .hide {
      visibility: hidden;
      position: absolute;
    }
  }
}

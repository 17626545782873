@import "../scss/variables";

.nav-wrapper {
  background: $primary;
  .navigation {
    .clickable {
      cursor: pointer;
    }
    h3 {
      margin-bottom: 0px;
    }
    width: 85%;
    margin: 0 auto;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .items {
      display: flex;
      .nav-link {
        display: none;

        @media #{$sm} {
          display: block;
        }
      }
    }
    .hamburger {
      display: block;
      color: white;
      .hamburgerClosed {
        display: none;
        .nav-link {
          display: none;
        }
      }
      .hamburgerOpen {
        display: block;
        position: absolute;
        top: 60px;
        right: 0;
        z-index: 1;
        background-color: $primary;
        .nav-link {
          display: block;
        }
      }
      @media #{$sm} {
        display: none;
      }
    }
  }
}

input {
  width: 300px;
  border: none;
  border-bottom: 2px solid $primary;
  outline: none;
  &:focus {
    border-bottom: 2px solid $turquoise;
  }
}

input[type="button"],
button {
  width: 300px;
  border-radius: 5px;
  padding: 0.5em 1.5em;
  display: block;
  background: $primary;
  border: $primary;

  color: white;
  &:disabled {
    background: grey;
    border: grey;
  }
}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@import "../scss/variables";

.item-one {
  .explore-form {
    position: relative;

    input {
      @media #{$md} {
        width: 90%;
      }
    }

    .icon {
      color: $primary;
      position: absolute;
      right: 0;

      @media #{$md} {
        right: 10%;
      }
    }
  }
}
.gallery-wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .gallery-card {
    height: 90px;
    width: 300px;
    margin: 3px 3px 0px 0;
    box-shadow: rgba(0, 0, 0, 10%) 0 0 0 1000000px inset;
    &:hover {
      cursor: pointer;
      box-shadow: none;
    }

    @media #{$md} {
      width: 32.7%;
    }
  }

  @media #{$md} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;
   }

}

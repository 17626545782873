.tab {
    display: flex;
    justify-content: center;
    @media #{$md} {
      justify-content: flex-start;
    }

    p {
      margin: 1em;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }

    .active {
      display: flex;
      justify-content: center;
      position: relative;
      color: $primary;
      border-bottom: 2px solid $primary;
      &::before {
        content: "";
        position: absolute;
        bottom: -32%;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $primary;
        animation-duration: 0.5s;
        animation-name: slidein;
      }
      &::after {
        content: "";
        position: absolute;

        bottom: -5px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid white;
        animation-duration: 0.5s;
        animation-name: slidein;
      }
    }
  }


// animations
@keyframes slidein {
    from {
      height: 45%;
    }
    to {
      height: 0%;
    }
  }
  
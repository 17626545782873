@import "../scss/variables";

.text-editor {
    margin-top: 10px;
    width: 300px;
    box-shadow: $boxshadow;
    min-height: 300px;
  
    .ce-block__content {
      width: 85%;
    }
    .ce-toolbar__content {
      width: 90%;
      span {
        color: black;
      }
    }
  
    @media #{$md} {
    margin-left: 10px;
      margin-top: 0;
      flex-basis: 53%;
      min-height: 500px;
    }
  }
@import "bootstrap/scss/bootstrap";
@import "../scss/gallery.scss";
@import "../scss/variables";
@import "../scss/addslide.scss";
@import "../scss/editPage.scss";
@import "../scss/slide.scss";
@import "../scss/settings.scss";
@import "../scss/form.scss";
@import "../scss/tab.scss";
@import "../scss/texteditor.scss";
@import "../scss/navbar.scss";
@import "../scss/hover.scss";

// fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700&family=Source+Sans+Pro:wght@200;400;700;900&display=swap");

* {
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.5px;

    h1 {
        font-size: $hOne;
    }
    h2 {
        font-size: $hTwo;
    }
    h3 {
        font-size: $hThree;
        //margin-bottom: 0px;
    }
    h4 {
        font-size: $hFour;
    }
    p,
    li {
        margin: 0;
        font-size: $body;
    }
    a {
        text-decoration: none;
    }
}

.wrapper {
    position: relative;
    width: 90%;
    margin: 10px auto;
}

.spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 40px;
    margin-top: 2em;
}

[placeholder]:focus::-webkit-input-placeholder {
    transition: text-indent 0.2s ease;
    text-indent: -100%;
    opacity: 1;
}

.background-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.loginPageWrapper {
    margin: 0 auto;
    width: 350px;

    @media #{$md} {
        width: 550px;
    }
}

// styles for formContainer
.formContainer {
    border-radius: 7px;
    margin: 0 auto;
    max-width: 700px;
    min-height: 400px;
    // flex properties
    display: flex;
    justify-content: center;
    align-items: center;

    // mediaQueries
    @media #{$sm} {
        box-shadow: $boxshadow;
    }
}

.login-form-wrapper {
    h1 {
        margin-left: 0;
        color: $primary;
        position: relative;
        text-align: left;
        &:after {
            content: "";
            position: absolute;
            width: 12%;
            height: 4px;
            background-color: $primary;
            bottom: 0; //to place it in bottom
            left: 0;
            margin-left: 2px;
            border-radius: 2px;
        }
    }
    width: 250px;
    .input-type-number-wrapper {
        display: flex;
        justify-content: space-between;

        margin: 5em 0;
        .input {
            font-size: $hTwo;

            width: 50px;
            border-width: 0 0 4px;
            border-color: grey;
            text-align: center;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &:focus {
                border-color: $primary;
            }
        }
    }
    button {
        margin: 0;
        width: 250px;
    }
}

// slideshowcard
.slideShowCardWrapper {
    width: 350px;
    margin: 0 auto;

    .slideShowCard {
        position: relative;
        height: 300px;
        width: 332px;
        display: flex;
        align-items: flex-end;
        box-shadow: $boxshadow;
        border-radius: 10px;
        background-color: $secondary;
        margin: 0.5em;
        &:nth-child(even) {
            background-color: $red;
        }
        .edit {
            position: absolute;
            top: -10px;
            left: 78%;
            background: none;
            color: $primary;
            padding: 1em;

            border: none;
            font: inherit;
            cursor: pointer;
            height: 52px;
            width: 52px;
            background: #082d4100;
            border-radius: 50%;
            transition: 0.5s ease;
            .icon {
                path {
                    stroke: #ffffffa1;
                    stroke-width: 20px;
                }
            }
            &:hover {
                background: #ffffffe7;
            }
        }
        .content {
            display: flex;
            flex-direction: column;
            background-color: white;
            width: 100%;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            .desc {
                padding: 1em 1em 0 1em;
                h3 {
                    font-size: $hThree;
                }
                p {
                    &:last-child {
                        font-weight: 200;
                    }
                }
            }
        }

        h3,
        p {
            margin: 0;
        }
    }

    button {
        margin: 1em;
        width: 300px;
        padding: 7px;
        background: $primary;
        color: white;
        border: none;
        border-radius: 5px;
        align-self: center;
        a {
            color: white;
            text-decoration: none;
        }
    }

    .editMessage {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
    }

    @media #{$md} {
        width: 728px;
        display: flex;
        flex-wrap: wrap;
    }
    @media #{$xl} {
        width: 1092px;
    }
}
iframe {
    width: 100vw;
    height: 100vh;
    border: none;
}

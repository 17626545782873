.hover-container {
  display: inherit;
  width: 346px;
  .hover {
    background-color: rgb(255, 255, 255);
    width: 14px;
    border-radius: 10px;
    filter: blur(10px);
    margin: 0 -5px 0 -5px;
    z-index: -1;
    &.show {
      background-color: blueviolet;
    }
  }
}

@import "../scss/variables";

.createSlideshow {
  margin: 10px;

  form {
    display: flex;
    flex-direction: column;

    input,
    button {
      margin: 15px 0;
      width: 100%;
    }

    @media #{$md} {
      margin-top: 3em;
      margin: 0 auto;
      width: 600px;
    }
  }
}
.colorPicker {
  width: 35px;
}

.wrapper {
  .user-feedback {
    color: $primary;
    position: absolute;
    right: 0;
    top: 6%;
    @media #{$md} {
      top: 10%;
    }
  }
  .controls {
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    .item-one {
      input[type="button"] {
        display: none;
      }

      @media #{$md} {
        flex-basis: 50%;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        input[type="button"] {
          display: block;
        }
      }
    }

    .file-uploader {
      #file-input {
        display: none;
      }

      label[for="file-input"] {
        margin-bottom: 1em;
        min-width: 300px;
        padding: 0.5em 1.5em;
        text-align: center;
        color: #fff;
        background-color: $primary;
        border-radius: 5px;
        cursor: pointer;
        transition: ease 0.3s;

        &:hover {
          background-color: #06202e;
        }
      }
    }

    .button-small-screen {
      @media #{$md} {
        display: none;
      }
    }

    .calendar {
      .datepicker {
        margin: 15px 5px 15px 0;
        outline: none;
        border: none;
        border-bottom: 2px solid $primary;
      }
      @media #{$lg} {
        display: flex;
      }
    }
    span {
      cursor: pointer;
      margin: 0.2em 0;
      color: $red;
    }
    .colorPickerText {
      color: black;
      width: 50%;
      cursor: auto;
      //margin-left: 10px;
    }

    @media #{$md} {
      align-items: flex-start;
      flex-direction: row;
    }
  }
  .imagesPreviewContainer {
    height: 250px;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }
  .preview {
    scroll-snap-align: start;

    height: 250px;
    width: 300px;
    margin: 1em 0;
    border-radius: 0.2vw;

    @media #{$md} {
      width: 606px;
    }
  }
}
.meetings-container{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  .radio-buttons{
    width: auto;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    
    label input{
      width: 10px;
      margin-right: 10px;
    }
  }
  .meetingForm {
    flex-direction: column;
    input[type="button"] {
      display: block;
      margin-top: 20px;
    }
  }
}

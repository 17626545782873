@import "../scss/variables";

.settings-wrapper {
  min-height: 80vh;

  margin: 0 auto;

  @media #{$md} {
    width: 90%;
    box-shadow: $boxshadow;
  }

  .settings-container {
    .tab {
      justify-content: center;
    }

    .component-wrapper {
      margin: 0 auto;
      padding-bottom: 2em;

      @media #{$md} {
        width: 80%;
      }
      @media #{$lg} {
        width: 60%;
      }
      .profile-container,
      .timers-container {
        margin: 2em;
      }

      .add-knownnames-form {
        input,
        button {
          width: 100%;
          margin: 1em 0;
        }
      }
      .persons-list {
        td::first-letter {
          text-transform: capitalize;
        }
        td button {
          width: 0;
          margin: 0;
          color: inherit;
          padding: 0;
          cursor: pointer;
        }
      }
    }

    .timers-container {
      .th-center {
        text-align: center;
      }
      position: relative;
      input {
        width: 40px;
        text-align: center;

        @media #{$md} {
          width: 55px;
        }
      }
      .user-feedback {
        position: absolute;
        top: -35px;
      }
    }
  }
}

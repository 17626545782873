//Colors
$primary: #082D41;
$secondary: #d9ebf2;
$red: #fe424d;
$turquoise: #1aa6b7;


//Font sizes
$body: 16px;
$hOne: 40px;
$hTwo: 30px;
$hThree: 25px;
$hFour: 20px;


//Screen sizes
$xs: #{"only screen and (min-width: 300px)"};
$sm: #{"only screen and (min-width: 576px)"};
$md: #{"only screen and (min-width: 768px)"};
$lg: #{"only screen and (min-width: 992px)"};
$xl: #{"only screen and (min-width: 1200px)"};

// box shadow
$boxshadow: 0 4px 8px 0 rgba(0, 0, 0, 0.125), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
